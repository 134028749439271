.root{
    flex-grow: 1;
    width: 100%;
}

.topNavIndicatorColor{
    background-color: #e8e8e8;
    color: #e8e8e8;
}

.topNavTextColor{
    background-color: blue,
}

/* Styles for AntTabs */
.AntTabs-root {}

.AntTabs-indicator {
  background-color: #05b6b7;
}

/* Styles for AntTab */
.AntTab-root {
  text-transform: none;
  min-width: 72px;
  margin-right: 0;
}

.AntTab-root:hover {
  color: #05b6b7;
  opacity: 1;
  transition: 0.2s;
}

.AntTab-root.selected {
  color: #05b6b7;
  font-weight: 500; /* or any desired font weight */
  transition: 0.2s;
}

.AntTab-root:focus {
  color: #05b6b7;
  transition: 0.2s;
}