.App {
  display: flex;
  /* justify-content: space-between; */
  /* height: 100vh; */
  height: 100%;
}

.app-left {
  width: 50%;
  min-height: 100vh;
}
.app-right {
  width: 50%;
  min-height: 100vh;
  background-image: url('./images/jafplus-adesao-002.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  background-size: cover;
  z-index: 10;
  background-color: #05b6b7;
  display: flex;
  flex-direction: column;
}

.logo-right {
  height: 250px;
  margin: 20% 15% 10% 15%;
}

.text-right {
  margin: 0% 15%;
  color: white;
}
.text-right h2 {
  font-size: 35px;
  margin-bottom: 5%;
}
.text-right p {
  font-size: 16px;
}

.text-right-footer {
  margin: 10% 15%;
  color: white;
}

/* .app-right img {
  width: 50%;
  opacity: 60%;
  margin-top: 50%;
  margin-left: 50%;
  transform: translate(-50%, -50%);
} */

@media (max-width: 800px) {
  .app-right {
    width: 0;
    display: none !important;
  }
  .app-left {
    width: 100%;
    /* display: flex; */
  }
}
